<template>
		<template v-if="settings.debug === true">
			<!-- SHOW DEBUG FOR DOOR OPEN LOCATION VALIDATION -->
			<div class="debug-wrapper ion-margin-bottom">
				<p v-if="userCurrentCordinates !== null">COORDS: (Update interval {{ settings.updatePositionAtBackgroundIntervalSeconds }}s):<br>{{ userCurrentCordinates.coords.longitude }} {{ userCurrentCordinates.coords.latitude }}</p>
				<p>FAILURES: (When BT/GPS available)<br><ion-text color="danger">
					<p v-for="failure in doorOpenFailures" :key="failure.timestamp">
						time: {{ failure.timestamp }} - locationId: {{ failure.locationId }}
					</p>
					</ion-text></p>
				<p><ion-text :color="successfulLocationValidation.beacon !== null ? 'secondary' : 'white'">SUCCESS: (Last valid door open)</ion-text><br />
					<vue-json-pretty :showLength="true" :deep="2" :deepCollapseChildren="true" :data="successfulLocationValidation" />
				</p>
				<p>GPS WATCH: (When door open)<br>
				Inside area:<br>{{ debugInsideAreaArray }}</p>
				<template v-if="debugFoundPositionsArray.length > 0">
					<p v-for="positionData in debugFoundPositionsArray" :key="positionData.timestamp">
						{{ positionData.coords.latitude }} {{ positionData.coords.longitude }}
					</p>
				</template>
			</div>

			<p class="ion-margin-top ion-margin-bottom">
				<vue-json-pretty :showLength="true" :deep="2" :deepCollapseChildren="true" :data="{'userConfirmableEvents': userConfirmableEvents.length, 'canUserConfirm': canUserConfirm, 'userCoords': $store.state.door.userCurrentCordinates}" />
			</p>

			<p class="ion-margin-top ion-margin-bottom">
				doorAndLocationData:
				<vue-json-pretty :showLength="true" :deep="0" :deepCollapseChildren="true" :data="doorAndLocationData" />
			</p>
		</template>


		<h1 class="front-main-header">{{ $t("wiseGymcard.greeting") + this.user?.firstName + "!" }}</h1>

		<h3 class="front-subheader"> {{ $t("wiseGymcard.gymInformation") }}</h3>



		<template v-if="doorAndLocationData && doorAndLocationData.locations.length > 0">

			<!-- LOCATION SELECTOR -->
			<ion-button v-if="doorAndLocationData && doorAndLocationData.locations.length > 1"   
				color="primary"
				expand="block"
				size="large"
				@click="openDoorAndLocationPicker()">
				{{ getLocationNameByLocationId(doorDataSelectedLocationId) }}
			</ion-button>

			<!-- ADMIN OPEN DOOR -->
			<ion-button v-if="user?.isAdmin && user?.isAdmin.doorAccess" 
			color="primary"
			expand="block"
			size="large"
			@click="initDoorOpen(true)">
				{{ $t("wiseGymcard.doorOpen.adminOpenDoor") }}
			</ion-button>

			<!-- Hide Check-In and Open Door buttons if not location selected (locationSettings needed to check checkinOnly and checkinAlso) -->
			<template v-if="doorDataSelectedLocationId !== null">
				<!-- CHECK-IN -->
				<ion-button v-if="checkinAlso === true || checkinOnly === true" class=""
				color="primary"
				expand="block"
				size="large"
				@click="initDoorOpen(false, true)">
					Check-In
				</ion-button>

				<!-- OPEN DOOR -->
				<ion-button v-if="checkinOnly === false" 
				color="primary"
				expand="block"
				size="large"
				class=""  @click="initDoorOpen(false, false)">
					{{ $t(`wiseGymcard.doorOpen.${settings.doorOpenButtonCustomText ? settings.doorOpenButtonCustomText : 'openDoor'}`, { msg: settings.doorOpenButtonCustomName }) }}
				</ion-button>
			</template>
		</template>	

		<ion-row v-if="Object.keys(this.ownEventsObject).length" class="row-title-button ion-justify-content-between ion-align-items-center">
			<ion-col>
				<h3> {{$t("wiseGymcard.bookings") }}</h3>
			</ion-col>

			<ion-col class="ion-text-end">
				<ion-button fill="clear" size="small" color="secondary" @click="() => $router.push(`fitness`)"> {{ $t("wiseGymcard.showAll") }} </ion-button>
			</ion-col>
		</ion-row>

		<ion-list class="card-group" lines="inset" v-if="Object.keys(this.ownEventsObject).length">
			<ion-item v-for="event in ownEventsObject.slice(0,3)" :key="event.activityId" @click="() => $router.push(`fitness/event/${event.activityId}`)">
				<ion-label class="ion-no-margin">
					<h3 class="list-text-header"> {{ event.name }}</h3>
					<p class="list-text">{{  event.venue + "," }} {{ event.activityStartTime }}</p>
				</ion-label>
				<ion-thumbnail slot="end">
                            <ion-img class="wise-thumbnail" :src="this.defaultFitnessImage" alt="Event enroll thumbnail" />
				</ion-thumbnail>
			</ion-item>
		</ion-list>

				<!-- CONFIRM GYM EVENT -->
				<ion-button 
					color="primary"
					size="large"
					expand="block"
				v-if="canUserConfirm && (userConfirmableEvents !== null && userConfirmableEvents.length > 0)" @click="handleEventConfirm()">
			{{ userConfirmableEvents.length > 1 ? $t("wiseGymcard.confirm.events") : $t("wiseGymcard.confirm.event") }}
		</ion-button>

		<ion-row class="row-title-button ion-justify-content-between ion-align-items-center">
			<ion-col>
				<h3> {{$t("wiseGymcard.newsAndBenefits") }} </h3>
			</ion-col>
			<ion-col class="ion-text-end">
				<ion-button  size="small" fill="clear" color="secondary" @click="() => $router.push(`today`)"> {{ $t("wiseGymcard.showAll") }} </ion-button>
			</ion-col>
		</ion-row>

		

			<ion-list v-if="listArticles.length > 0" class="card-group card-group-articles" lines="full">
				<ion-item  v-for="article in listArticles.slice(0,3)" :key="article.id" @click="() => $router.push(`today/article/${article.id}`)">
					<ion-label class="ion-no-margin">
						<h3 class="list-text-header">{{ article.title }}</h3>
						<p class="list-text">{{ article.summary }}</p> 
					</ion-label>
					<ion-thumbnail slot="end">
						<ion-img class="wise-thumbnail" @ionError="imageErrorArticle($event)" :src="article.smallThumbnail" alt="Article thumbnail" />
					</ion-thumbnail>
				</ion-item>
			</ion-list>

			<div class="center" v-if="listArticles.length === 0">
				{{ $t("articles.noArticlesText") }}
			</div>

		<ion-row v-if="this.userAccessRights !== null" class="row-title-button ion-justify-content-between ion-align-items-center">
			<ion-col>
				<h3> {{$t("wiseGymcard.accessRights") }} </h3>
			</ion-col>
		</ion-row>
			<ion-list v-if="this.userAccessRights" class="card-group card-icon-group" lines="inset">
				<ion-item v-for="accessRight in userAccessRights" :key="accessRight.id">
					<i class="wn-icon user" slot="start"></i>
					<ion-label>
						<h3 class="list-text-header"> {{ accessRight.name }}</h3>
						<p class="list-text">{{ getValidityInformationText(accessRight) }}</p>
					</ion-label>
				</ion-item>
			</ion-list>

			<div class="center" v-if="!Object.keys(userAccessRights).length">
				{{ $t("wiseGymcard.noAccessRights") }}
			</div>

			<ion-refresher slot="fixed" @ionRefresh="doRefresh($event)">
					<ion-refresher-content></ion-refresher-content>
			</ion-refresher>
		</template>


		<!-- OLD FRONT JUST FOR REFERENCE -->

		<!-- <div v-if="doorDataSelectedLocationId !== null && (getValidityInformationTitle !== '' || getValidityInformationText !== '' || getRoleValidityInformation.title !== '' || getRoleValidityInformation.text !== '')" class="validity-information ion-text-center ion-margin-bottom italic"> -->
			<!-- VALIDITY INFORMATION -->
			<!-- <span v-if="getValidityInformationTitle !== ''">{{ getValidityInformationTitle }}</span>
			<h4 class="ion-margin-top" v-if="getValidityInformationText !== ''">{{ getValidityInformationText }}</h4> -->
			<!-- ROLE VALIDITY INFORMATION -->
			<!-- <template v-if="commonValidValues && commonValidValues.locationValidUntilArray !== false">
				<span v-if="getRoleValidityInformation.title !== ''">{{ getRoleValidityInformation.title }}</span>
				<h4 class="ion-margin-top" v-if="getRoleValidityInformation.text !== ''">{{ getRoleValidityInformation.text }}</h4>
			</template>
		</div> -->

		<!-- CHOOSE DOOR OPEN LOCATION -->
		<!-- <ion-button v-if="doorAndLocationData && doorAndLocationData.locations.length > 1" class="btn-border-light ion-margin-bottom" color="dark" shape="round" expand="block" @click="openDoorAndLocationPicker()">
			{{ getLocationNameByLocationId(doorDataSelectedLocationId) }}
		</ion-button> -->

		<!-- SHOW INFO IF RETURNED ZERO LOCATIONS FOR DOORS -->
		<!-- <div v-if="doorAndLocationData && doorAndLocationData.locations.length === 0" class="ion-text-center ion-margin-bottom">
			<ion-text color="warning">
        <h5>{{ $t("wiseGymcard.error.noDoors") }}</h5>
      </ion-text>
		</div> -->

		<!-- <template v-if="doorAndLocationData && doorAndLocationData.locations.length > 0"> -->
			<!-- ADMIN OPEN DOOR -->
			<!-- <ion-button v-if="user.isAdmin && user.isAdmin.doorAccess" class="btn-border-light ion-margin-bottom" color="dark" shape="round" expand="block" @click="initDoorOpen(true)">
				<ion-icon slot="start" class="ion-margin-end" :icon="lockClosed" />
				{{ $t("wiseGymcard.doorOpen.adminOpenDoor") }}
			</ion-button> -->

			<!-- Hide Check-In and Open Door buttons if not location selected (locationSettings needed to check checkinOnly and checkinAlso) -->
			<!-- <template v-if="doorDataSelectedLocationId !== null"> -->
				<!-- CHECK-IN -->
				<!-- <ion-button v-if="checkinAlso === true || checkinOnly === true" class="btn-border-light ion-margin-bottom" color="dark" shape="round" expand="block" @click="initDoorOpen(false, true)">
					<ion-icon slot="start" class="ion-margin-end" :icon="enterOutline" />
					Check-In
				</ion-button> -->

				<!-- OPEN DOOR -->
				<!-- <ion-button v-if="checkinOnly === false" class="btn-border-light ion-margin-bottom" color="dark" shape="round" expand="block" @click="initDoorOpen(false, false)">
					<ion-icon slot="start" class="ion-margin-end" :icon="lockClosed" /> -->
					<!--
					// OPEN DOOR TRANSLATIONS WITH AND WIHTOUT CUSTOM NAME
					openDoor = Open door = Open door
					openDoorCheckIn = Open door / Check-in
					openCustomDoor = Unlock %{msg} door (Unlock SAMK Gym door)
					openCustomDoorCheckIn = Open %{msg} door / Check-in (Open SAMK Gym door / Check-in)
					-->
					<!-- {{ $t(`wiseGymcard.doorOpen.${settings.doorOpenButtonCustomText ? settings.doorOpenButtonCustomText : 'openDoor'}`, { msg: settings.doorOpenButtonCustomName }) }}
				</ion-button>
			</template>
		</template> -->

		<!-- <ion-button v-if="activeHost.ecomUrl?.length > 0" :href="`${activeHost.ecomUrl}?lang=${SUPPORT_LOCALES[$i18n.locale].locale}`" color="dark" class="btn-border-primary" shape="round" expand="block">
			<ion-icon class="ion-margin-end" :icon="cart" />
			{{ $t("wiseGymcard.toOnlineStore") }}
		</ion-button>
	</div> -->


<script>
import { lockClosed, cart, alarmOutline, enterOutline, personCircleOutline, arrowForwardOutline, personOutline } from "ionicons/icons";
import { Capacitor } from "@capacitor/core";
import { Device } from '@capacitor/device';
import { Geolocation } from '@capacitor/geolocation';
import { loadingController, modalController, IonRefresher, IonRefresherContent } from "@ionic/vue";
import { mapState, mapGetters, mapMutations, mapActions } from "vuex";
import { SUPPORT_LOCALES } from "@/i18n";
import axios from 'axios';
import { IBeacon } from '@ionic-native/ibeacon';
import WiseDoorAndLocationPicker from "@/WiseCore/components/WiseDoorAndLocationPicker.vue";
import WiseEventConfirmPicker from "@/WiseCore/components/WiseEventConfirmPicker.vue";
import locationAndBluetooth from '@/helpers/locationServicesAndBluetooth'

import moment from 'moment';

export default {
	name: "WiseFrontLoggedIn2",
	mixins: [locationAndBluetooth],
	data() {
		return {
			SUPPORT_LOCALES,
			// Icons
			lockClosed,
			cart,
			alarmOutline,
			enterOutline,
			personCircleOutline,
			arrowForwardOutline,
			personOutline,
			// For debug use
			debugFoundPositionsArray: [],
			debugInsideAreaArray: [],
			// Needed to work
			cordinatesPositionWatchId: null,
			locationValidationSuccess: false,
			gpsLocationFound: null,
			deviceBluetoothAvailability: null,
			deviceLocationAvailability: null,
			isCheckinOpen: false,
			commonValidValues: null,
			userAccessRights: {},
			seacrhTimeoutSeoncdsInterval: null
		}
	},
	watch: {
		doorDataSelectedLocationId() {
			if(typeof this.doorDataSelectedLocationId === "number") {
				console.log('%cSelected locationId changed to:','color: aqua; padding: 5px 0', this.doorDataSelectedLocationId)
				this.getCommonValidValues()
			}
		},
	},
	async mounted() {
		// mounted() run once when user logs in

		try {
			await this.getDoorAndLocationDataFromServer()
		} catch (error) {
			console.log(error)
		}

		try {
			await this.getFitnessData()
		} catch (error) {
			console.error("WiseFrontLoggedIn:", error)
		}

		this.getUserAccessRights();

		try {
			await this.loadArticles()
		} catch(error) {
			console.error("WiseFrontLoggedInArticles: ")
		}

		if(typeof this.doorDataSelectedLocationId === "number") {
			this.getCommonValidValues();
		}
		
		const device = Capacitor.getPlatform();

		if (device === "android" || device === "ios") {
			try {
				// Subsribe to didRangeBeaconsInRegion event
				this.iBeaconDelegate.didRangeBeaconsInRegion().subscribe((beaconRange) => {
					// Check if beacon uuid match and is inside acceptedBeaconDistance
					const beaconCheckResults = this.checkRangedBeacons(beaconRange) // Returns { beaconFound: (bool), acceptedBeaconDistance: (bool), beacon: (obj) }

					if(beaconCheckResults?.beaconFound === true) {

						// Array of ranged beacons
						this.mutateDoorToOpenRangedBeacons(beaconRange.beacons)

							if(beaconCheckResults.acceptedBeaconDistance === true) {
								console.warn(beaconCheckResults)
								// Location validated succesfully with iBeacon
								// Saves timestamp, user cordinates and beacon to vuex state.successfulLocationValidation
								this.mutateSuccessfulLocationValidation({ beacon: beaconCheckResults.beacon.beaconUUID, locationId: this.doorDataSelectedLocationId })
								this.locationValidationSuccess = true
								this.stopPositionWatch()
								this.stopBeaconRanging()
								clearTimeout(this.locationValidationSearchTimeout)
								this.openDoorOverlay.dismiss();
								this.openDoor(this.doorToOpenReaderId)
							} else {
								// Beacon too far away
								console.error("Beacon too far away. beaconCheckResults", beaconCheckResults)
								this.lastRangedBeaconData = beaconCheckResults
							}
					}
				}, (error) => {
					console.error(error)
				}
			);
			} catch (error) {
				console.error("didRangeBeaconsInRegion().subscribe()", error)
			}

		}
		IBeacon.setDelegate(this.iBeaconDelegate)
	},
	computed: {
		...mapState({
			settings: state => state.common.settings,
			user: state => state.user.user,
			userLocale: state => state.common.userLocale,
			userCurrentCordinates: state => state.door.userCurrentCordinates,
			doorAndLocationData: state => state.door.doorAndLocationData,
			doorDataSelectedLocationId: state => state.door.doorDataSelectedLocationId,
			successfulLocationValidation: state => state.door.successfulLocationValidation,
			doorOpenFailures: state => state.door.doorOpenFailures,
			beaconsInRanging: state => state.door.beaconsInRanging,
			iBeaconDelegate: state => state.door.iBeaconDelegate,
			doorToOpenReaderId: state => state.door.doorToOpenReaderId,
			doorToOpenRangedBeacons: state => state.door.doorToOpenRangedBeacons,
			listArticles: state => state.common.listArticles,
			errorImage: state => state.common.errorImage,
			defaultFitnessImage: state => state.common.defaultFitnessImage,
			ownEventsObject: state => state.gym_common.ownEventsObject
		}),
		...mapGetters({
			activeHost: "common/activeHost",
			userConfirmableEvents: "gym_common/userConfirmableEvents",
			canUserConfirm: "gym_common/canUserConfirm"
		}),

		

		checkinOnly() {
			return this.doorAndLocationData?.locations.find(location => location.locationId === this.doorDataSelectedLocationId)?.locationSettings?.checkinOnly === true ? true : false
		},
		checkinAlso() {
			return this.doorAndLocationData?.locations.find(location => location.locationId === this.doorDataSelectedLocationId)?.locationSettings?.checkinAlso === true ? true : false
		},

		getValidityInformationTitle() {
			let validityTitle = this.$t("wiseGymcard.validityInformation.fitnessTimeExpired");
			// No valid access right now or in the future
			if (!this.commonValidValues?.currentlyValidFound && !this.commonValidValues?.futureValidFound) {
				return validityTitle;
			}

			// Access right starts in the future
			if (this.commonValidValues?.futureValidFound) {
				if(this.commonValidValues?.usableQuantity !== null) {
					// Title when person have usableQuantity
					validityTitle = this.$t("wiseGymcard.validityInformation.fitnessTimeIsValid");
					return validityTitle;
				} else {
					validityTitle = this.$t("wiseGymcard.validityInformation.fitnessTimeIsValid");
					return validityTitle;
				}
			}

			// Access right is not permanent
			if (this.commonValidValues?.validTo !== null) {
				if(this.commonValidValues?.usableQuantity !== null) {
					// Title when person have usableQuantity
					validityTitle = this.$t("wiseGymcard.validityInformation.fitnessTimeIsValid");
					return validityTitle;
				} else {
					validityTitle = this.$t("wiseGymcard.validityInformation.fitnessTimeIsValid");
					return validityTitle;
				}
			}

			// Permanent access right
			if(this.settings?.validityInformation?.alwaysShowValidity) {
				validityTitle = this.$t("wiseGymcard.validityInformation.fitnessTimeIsValid");
				return validityTitle;
			} else {
				if(this.commonValidValues?.usableQuantity !== null) {
					// Permanent access right no need to show, but user have usableQuantity and we also show title
					validityTitle = this.$t("wiseGymcard.validityInformation.fitnessTimeIsValid");
					return validityTitle;
				} else {
					validityTitle = ""
					return validityTitle;
				}
			}
		},
		getValidityInformationTextOld() {
			let validityText = "";

			// No valid access right now or in the future
			if (!this.commonValidValues?.currentlyValidFound && !this.commonValidValues?.futureValidFound) {
				return validityText;
			}

			// Access right starts in the future
			if (this.commonValidValues?.futureValidFound) {
				// Has end date
				if(this.commonValidValues?.validTo !== null) {
					if(this.commonValidValues?.usableQuantity !== null) {
						validityText = `${this.commonValidValues?.usableQuantity} ${this.$t("wiseGymcard.validityInformation.visitsLeft")}, ${this.$t("wiseGymcard.validityInformation.mustBeUsedIn")} ${this.$filters.dateShort(this.commonValidValues?.validFrom)} - ${this.$filters.dateShort(this.commonValidValues?.validTo)}`
						return validityText;
					} else {
						validityText = `${this.$filters.dateShort(this.commonValidValues?.validFrom)} - ${this.$filters.dateShort(this.commonValidValues?.validTo)}`
						return validityText;
					}
				}
				// Starts in future, without end date
				if(this.commonValidValues?.validTo === null) {
					if(this.commonValidValues?.usableQuantity !== null) {
						validityText = `${this.commonValidValues?.usableQuantity} ${this.$t("wiseGymcard.validityInformation.visitsLeft")}, ${this.$t("wiseGymcard.validityInformation.canUsedFrom")} ${this.$filters.dateShort(this.commonValidValues?.validFrom)}`
						return validityText;
					} else {
						validityText = `${this.$t("wiseGymcard.validityInformation.from")} ${this.$filters.dateShort(this.commonValidValues?.validFrom)}`
						return validityText;
					}
				}
			}

			// Access right is not permanent
			if (this.commonValidValues?.validTo !== null) {
				if(this.commonValidValues?.usableQuantity !== null) {
					validityText = `${this.commonValidValues?.usableQuantity} ${this.$t("wiseGymcard.validityInformation.visitsLeft")}, ${this.$t("wiseGymcard.validityInformation.mustBeUsedBefore")} ${this.$filters.dateShort(this.commonValidValues?.validTo)}`
					return validityText;
				} else {
					validityText = `${this.$t("wiseGymcard.validityInformation.until")} ${this.$filters.dateShort(this.commonValidValues?.validTo)}`
					return validityText;
				}
			}

			// Permanent access right
			if(this.settings?.validityInformation?.alwaysShowValidity) {
				validityText = this.$t("wiseGymcard.validityInformation.untilFurtherNotice");
				return validityText;
			} else {
				if(this.commonValidValues?.usableQuantity !== null) {
					// Permanent access right no need to show, but user have usableQuantity
					validityText = `${this.commonValidValues?.usableQuantity} ${this.$t("wiseGymcard.validityInformation.visitsLeft")}`
					return validityText;
				} else {
					validityText = ""
					return validityText;
				}
			}
		},

		
		
		getRoleValidityInformation() {
			let roleText = {title: "", text: "" }

			// Check do we need to show roleValidity?
			if(this.settings?.validityInformation?.showRoleValidity !== true) {
				console.warn("In settings showRoleValidity is:", this.settings?.validityInformation?.showRoleValidity);
				return roleText
			}

			// Is role valid?
			if (!this.doorAndLocationData?.roleValidity.length > 0) {
				console.warn("No valid role found from roleValidity.");
				return roleText = {title: "", text: this.$t("wiseGymcard.validityInformation.noValidRoleFound")}
			}

			const validStartDate = this.doorAndLocationData?.roleValidity[0].validStartDate ? moment(this.doorAndLocationData?.roleValidity[0].validStartDate) : null;
			const validEndDate = this.doorAndLocationData?.roleValidity[0].validEndDate ? moment(this.doorAndLocationData?.roleValidity[0].validEndDate) : null;

			// Is role starting in the future?
			if(validStartDate !== null && validStartDate.isAfter(moment(), 'day')) {
				return roleText = {title: `${this.doorAndLocationData?.roleValidity[0].name}`, text: `${this.$t("wiseGymcard.validityInformation.roleValidFrom")} ${this.$filters.dateShort(validStartDate)}` }
			}

			// Is role permanently valid?
			if(validEndDate === null && validStartDate !== null) {
				return roleText = {title: `${this.doorAndLocationData?.roleValidity[0].name}`, text: this.$t("wiseGymcard.validityInformation.untilFurtherNotice") }
			}

			// Role is not permanently valid
			if(validEndDate !== null && validStartDate !== null) {
				return roleText = {title: `${this.doorAndLocationData?.roleValidity[0].name}`, text: `${this.$t("wiseGymcard.validityInformation.validUntil")} ${this.$filters.dateShort(validEndDate)}` }
			}

			// Fallback, should never reach here
			return roleText
		}
	},
	methods: {
	...mapMutations({
		mutateUserCurrentCordinates: "door/mutateUserCurrentCordinates",
		mutateSuccessfulLocationValidation: "door/mutateSuccessfulLocationValidation",
		mutateDoorOpenFailures: "door/mutateDoorOpenFailures",
		mutateUserbeaconsInRanging: "door/mutateUserbeaconsInRanging",
		mutateDoorToOpenReaderId: "door/mutateDoorToOpenReaderId",
		mutateDoorToOpenRangedBeacons: "door/mutateDoorToOpenRangedBeacons"
	}),
	...mapActions({
		getFitnessData: "gym_common/getFitnessData",
		getArticles: "common/getArticles",
		getDoorAndLocationDataFromServer: "door/getDoorAndLocationDataFromServer"}),

		//Perform refresh when user pulls down on view
		doRefresh(event) {
			this.loadArticles();
			this.getUserAccessRights();
			this.getDoorAndLocationDataFromServer();
			event.target.complete();

		},

		// Check do we have more than one confirmable event
		handleEventConfirm() {
			if(this.userConfirmableEvents.length === 1) {
				// Only one event
				this.$router.push(`fitness/event/${this.userConfirmableEvents[0].activityId}`)
				return
			}
			// Multiple events
			this.eventConfirmPicker(this.userConfirmableEvents)
		},
		
		// Loads app articles
		async loadArticles() {
			try {
				await this.getArticles();
				console.log("listArticles count: ", this.listArticles.length)
				this.loadingError = false;
				this.loadMoreDisabled = false
			} catch (error) {
				console.log("loadArticles error:", error)
			}
		},

		imageErrorArticle(event) {
			event.target.src= this.errorImage
		},

		imageErrorFitness(event) {
			event.target.src= this.defaultFitnessImage
		},
		// Validity information values
		getCommonValidValues() {
			const returnObj = {};
			//console.log(this.doorAndLocationData)
			// Get location
			returnObj['locationValidUntilArray'] = (this.doorAndLocationData.validUntilArray && Object.entries(this.doorAndLocationData.validUntilArray).length) ? this.doorAndLocationData.validUntilArray[this.doorDataSelectedLocationId] : false;
			// If no location array set, don't bother continuing
			if (!returnObj.locationValidUntilArray) {
				return this.commonValidValues = returnObj;
			}

			// Get validFrom and validTo as momentjs
			// Note that we use hardcoded 0 as index here.
			returnObj['validFrom'] = returnObj.locationValidUntilArray[0].validFrom ? moment(returnObj.locationValidUntilArray[0].validFrom).hour(0).minute(0).second(0) : null;
			returnObj['validTo'] = returnObj.locationValidUntilArray[0].validTo ? moment(returnObj.locationValidUntilArray[0].validTo).hour(23).minute(59).second(59) : null;

			// Get usableQuantity if it exists
			returnObj['usableQuantity'] = returnObj.locationValidUntilArray[0].usableQuantity ? returnObj.locationValidUntilArray[0].usableQuantity : null;

			// Check if access right starting point is in the future
			returnObj['futureValidFound'] = (returnObj.validFrom !== null && returnObj.validFrom.isAfter(moment(), 'day') && (returnObj.validTo === null || returnObj.validTo.isAfter(moment(), 'day')));

			// Currently valid, and continues to be valid until futher notice. Probably contract type.
			returnObj['continuousFound'] = ((returnObj.validFrom === null || returnObj.validFrom.isSameOrBefore(moment(), 'day')) && returnObj.validTo === null);

			// Valid right now (validFrom past or not set, validTo future or not set).
			returnObj['currentlyValidFound'] = (returnObj.validFrom === null || returnObj.validFrom.isSameOrBefore(moment(), 'day')) && (returnObj.validTo === null || returnObj.validTo.isSameOrAfter(moment(), 'day'));

			this.commonValidValues = returnObj;
		},

		async getUserAccessRights() {
			let returnObj = this.doorAndLocationData;
			console.log("User access rights returned", returnObj)

			function extractUniqueAccessRights(data) {
				const uniqueData = {};

				if (data && data.fullPersonCategoryData && Array.isArray(data.fullPersonCategoryData)) {
					data.fullPersonCategoryData.forEach(item => {
						if (item.id ) {
							if (!uniqueData[item.id]) {
								uniqueData[item.id] = {
									categoryId: item.categoryId ? item.categoryId : null,
									usableQuantity: item.usableQuantity ? item.usableQuantity: null,
									validFrom: item.validFrom ? moment(item.validFrom).hour(0).minute(0).second(0) : null,
									validTo: item.validTo ? moment(item.validTo).hour(23).minute(59).second(59) : null,
									locationId: item.locationId ? item.locationId : null,
									name: item.name ? item.name : null,
									// Check if access right starting point is in the future. Horrible syntax, I know.
									futureValidFound: (item.validFrom !== null && moment(item.validFrom).hour(0).minute(0).second(0).isAfter(moment(), 'day') && (item.validTo === null || moment(item.validTo).hour(23).minute(59).second(59).isAfter(moment(), 'day'))),
									// Currently valid, and continues to be valid until futher notice. Probably contract type.
									continuousFound: ((item.validFrom === null || moment(item.validFrom).hour(0).minute(0).second(0).isSameOrBefore(moment(), 'day')) && item.validTo === null),
									// Valid right now (validFrom past or not set, validTo future or not set).
									currentlyValidFound: (item.validFrom === null || moment(item.validFrom).hour(0).minute(0).second(0).isSameOrBefore(moment(), 'day')) && (item.validTo === null || moment(item.validTo).hour(23).minute(59).second(59).isSameOrAfter(moment(), 'day'))
								};
							}
						}
					});
				}

				return Object.entries(uniqueData).map(([id, data]) => ({ id: Number(id), ...data }));
			}

			const uniqueAccessRights = extractUniqueAccessRights(returnObj);
			this.userAccessRights = uniqueAccessRights;

		},

		getValidityInformationText(accessRight) {
			let validityText = "";
			//console.log(accessRight)

			// No valid access right now or in the future
			if (!accessRight?.currentlyValidFound && !accessRight?.futureValidFound) {
				return validityText;
			}

			// Access right starts in the future
			if (accessRight?.futureValidFound) {
				// Has end date
				if(accessRight?.validTo !== null) {
					if(accessRight?.usableQuantity !== null) {
						validityText = `${accessRight?.usableQuantity} ${this.$t("wiseGymcard.validityInformation.visitsLeft")}, ${this.$t("wiseGymcard.validityInformation.mustBeUsedIn")} ${this.$filters.dateShort(accessRight?.validFrom)} - ${this.$filters.dateShort(accessRight?.validTo)}`
						return validityText;
					} else {
						validityText = `${this.$filters.dateShort(this.userAccessRights?.validFrom)} - ${this.$filters.dateShort(this.userAccessRights?.validTo)}`
						return validityText;
					}
				}
				// Starts in future, without end date
				if(accessRight?.validTo === null) {
					if(accessRight?.usableQuantity !== null) {
						validityText = `${accessRight?.usableQuantity} ${this.$t("wiseGymcard.validityInformation.visitsLeft")}, ${this.$t("wiseGymcard.validityInformation.canUsedFrom")} ${this.$filters.dateShort(accessRight?.validFrom)}`
						return validityText;
					} else {
						validityText = `${this.$t("wiseGymcard.validityInformation.from")} ${this.$filters.dateShort(accessRight?.validFrom)}`
						return validityText;
					}
				}
			}

			// Access right is not permanent
			if (accessRight?.validTo !== null) {
				if(accessRight?.usableQuantity !== null) {
					validityText = `${accessRight?.usableQuantity} ${this.$t("wiseGymcard.validityInformation.visitsLeft")}, ${this.$t("wiseGymcard.validityInformation.mustBeUsedBefore")} ${this.$filters.dateShort(accessRight?.validTo)}`
					return validityText;
				} else {
					validityText = ` ${this.$t("wiseGymcard.validityInformation.validUntil")} ${this.$filters.dateShort(accessRight?.validTo)} ${this.$t("wiseGymcard.validityInformation.until").toLowerCase()}`
					return validityText;
				}
			}

			// Permanent access right
			if(this.settings?.validityInformation?.alwaysShowValidity) {
				validityText = `${this.$t("wiseGymcard.validityInformation.validUntil")} ${this.$t("wiseGymcard.validityInformation.untilFurtherNotice").toLowerCase()} `;
				return validityText;
			} else {
				if(accessRight?.usableQuantity !== null) {
					// Permanent access right no need to show, but user have usableQuantity
					validityText = `${accessRight?.usableQuantity} ${this.$t("wiseGymcard.validityInformation.visitsLeft")}`
					return validityText;
				} else {
					validityText = ""
					return validityText;
				}
			}

		},

		// If more than one confirmable events open event confirm picker to choose event
		async eventConfirmPicker(events) {
			// Create event picker modal
			const modal = await modalController.create({
				component: WiseEventConfirmPicker,
				cssClass: "wise-picker-modal",
				mode: "md",
				componentProps: {
					events
				},
			});
			return modal.present();
		},

		// STEP 01 Check if we have locationId and readerId (if not direct user to choose location / door)
		async initDoorOpen(admin = false, isCheckinOpen = false) {
			this.isCheckinOpen = isCheckinOpen
			// Check if we have location for door opening
			if(this.doorDataSelectedLocationId === null) {
				// No location selected, open location picker (and show door picker after location is choosed)
				this.openDoorAndLocationPicker(false, admin, true)
				return
			}
			// Get readers (Doors) for location (Only doors with readerSettings.appVisible = true)
			const doors = this.doorAndLocationData.readers.filter(door => door.locationId === this.doorDataSelectedLocationId && door.readerSettings.appVisible === true)
			// Check if location have more than one door
			if(doors.length > 1) {
				// Sort doors by readerSettings appOrder
				doors.sort((doorA, doorB) => {
					if((!doorA.readerSettings.appOrder && doorB.readerSettings.appOrder) || (doorA.readerSettings.appOrder > doorB.readerSettings.appOrder)) return 1;
					if((doorA.readerSettings.appOrder && !doorB.readerSettings.appOrder) || (doorA.readerSettings.appOrder < doorB.readerSettings.appOrder)) return -1;
					return 0;
				})
				// Show picker and let user choose which door to open
				this.openDoorAndLocationPicker(doors, admin, false, isCheckinOpen)
			} else {
				// Only one door
				if(admin === true) {
					this.adminDoorOpen(doors[0].readerId)
				} else {
					// Not admin
					if(this.isCheckinOpen === true && this.settings?.onCheckInValidateLocation !== true) {
						// If check-in and not needed to validate location just check-in
						this.openDoor(doors[0].readerId)
						return
					}

					this.checkLocationAndBluetoothServicesAvailability(doors[0].readerId)
				}
			}
		},
		// STEP 02 locationPicker (mutateDoorDataSelectedLocationId) / doorPicker calls checkLocationAndBluetoothServicesAvailability(readerId)
		async openDoorAndLocationPicker(doorsArray = false, admin = false, showDoorPicker = false, isCheckinOpen = false) {

			console.log("openDoorAndLocationPicker(): RUN doorsArray:", doorsArray, "admin", admin, "showDoorPicker", showDoorPicker)

			// Create modal picker
			const modal = await modalController.create({
				component: WiseDoorAndLocationPicker,
				cssClass: "wise-picker-modal",
				mode: "md",
				componentProps: {
					doors: doorsArray ? doorsArray : false,
					isCheckinOpen: isCheckinOpen
				},
			});

			// When modal closed
			modal.onDidDismiss().then(doorData => {
				// If clicked cancel from picker
				if(doorData?.data?.cancel === true) return
				// If we have data from picker
				if(doorData?.data?.readerId) {
					// If picker returned data we have door readerId (ADMIN = OPEN DOOR / NOT ADMIN = INIT VALIDATION)
					if(admin === true) {
						this.adminDoorOpen(doorData.data.readerId)
					} else {
						if(this.isCheckinOpen === true && this.settings?.onCheckInValidateLocation !== true) {
							// If check-in and not needed to validate location just check-in
							this.openDoor(doorData.data.readerId)
							return
						}

						this.checkLocationAndBluetoothServicesAvailability(doorData.data.readerId)
					}
				} else {
					// If user press open door button without choosing location, show location picker first and then initDoorOpen() to choose door
					if(showDoorPicker === true) {
						this.initDoorOpen(admin)
					}
				}
			})
			return modal.present();
		},
		// STEP 03 Check if we have location services / bluetooth available (USED FOR NON ADMIN USER)
		async checkLocationAndBluetoothServicesAvailability(readerId) {
			this.mutateDoorToOpenReaderId(readerId)

			console.log("Validate door open for readerId:", readerId)
			const timestampNowSeconds = Date.now() / 1000 | 0

			// CHECK IF WE NEED TO DO HOAX OPEN
			if(this.settings.openDoorOnLocationFailure === true) {
				// Count door open failures
				if(this.doorOpenFailures.length >= this.settings.maxTotalLocationFailures) {
					// CHECK IF FAILURES DONE IN ACCEPTABLE TIME
					if(timestampNowSeconds - this.doorOpenFailures[0].timestamp < this.settings.doorOpenLocationFailureHoaxInsideSecods) {
						// Fake overlay serach GPS and Beacons
						this.openDoorLoadingOverlay(`${this.$t("wiseGymcard.doorOpen.searchingLocationAndBeacon")}... <br> ${this.$t("wiseGymcard.doorOpen.timeOut")} (${this.settings.locationSearchTimeout / 1000}) ${this.$t("wiseGymcard.doorOpen.seconds")}.`)
						// Open door with delay
						// The idea is to prevent the user from realizing that the door has been opened without checking the location.
						setTimeout(() => {
							this.openDoorOverlay.dismiss();
							this.openDoor(readerId, true) // hoax = true
						}, this.settings.doorOpenLocationFailureHoaxWaitMsec);
						return
					} else {
						// Failure open(s) not in acceptable time
						// Reset door open failures
						this.mutateDoorOpenFailures(null)
					}
				}
			}

			const timestampConfirmedLocationSeconds = this.successfulLocationValidation.timestamp

			// CHECK IF WE HAVE CONFIRMED VALID LOCATION FROM CURRENT loicationId
			if(this.doorDataSelectedLocationId === this.successfulLocationValidation.locationId) {
				// Check if reuseLocationConfirmationLastMinutes is VALID
				if(timestampNowSeconds - timestampConfirmedLocationSeconds < this.settings.reuseLocationConfirmationLastMinutes*60) {
					console.log("successfulLocationValidation IS STILL VALID!")
					// Open door
					this.openDoor(readerId)
					return
				}
			}

			// Check if location services are turned on and authorized, if not offer settings
			this.deviceLocationAvailability = await this.checkDeviceLoactionAvailability(true) // true = openDoor
			console.log(JSON.stringify(this.deviceLocationAvailability))

			// STOP IF NO LOCATION SERVICES ARE AVAILABLE (NO REASON TO ONLY SEARCH BEACONS BECAUSE IT NEEDS LOCATION SERVICES TO WORK)
			if(this.deviceLocationAvailability.isLocationAvailable === false) return

			// IF WE ARE HERE, WE HAVE LOCATION SERVICES TURNED ON AND AUTHORIZED

			// Get Reader beacons
			const readerBeacons = this.doorAndLocationData.readers.find(reader => reader.readerId === readerId).beacons

			// If we have beacons check if bluetooth available
			if(readerBeacons.length > 0) {
				console.log("readerId:", readerId, " - have beacons, check if Bluetooth is available")
				this.deviceBluetoothAvailability = await this.bluetoothAvailability()
				console.log(JSON.stringify(this.deviceBluetoothAvailability))

				// BLUETOOTH IS AVAILABLE
				if(this.deviceBluetoothAvailability.isBluetoothAvailable === true) {
					// SEARCH GPS & BEACONS
					console.log("SEARCH GPS & BEACONS")
					this.initLocationSearchValidation(readerId, readerBeacons)
				}
				// BLUETOOTH NOT AVAILABLE
				else {
					// SEARCH ONLY GPS
					console.log("SEARCH ONLY GPS")
					// Handlebluetooth error and offer settings if possible
					// RETURNS continueWithoutBluetooth (boolean), is FALSE when user goes to settings
					const bluetoothErrorHandled = await this.handleBluetoothNotAvailableErrors(this.deviceBluetoothAvailability.getBluetoothState)
					console.log("bluetoothErrorHandled", bluetoothErrorHandled)
					if(bluetoothErrorHandled.continueWithoutBluetooth === true) {
						this.initLocationSearchValidation(readerId)
					}

					if(bluetoothErrorHandled.continueWithoutBluetooth === false) {
						this.saveDoorOpenAccessLog("App was not authorized to use Bluetooth and user chose to go App settings")
					}
				}
			} else {
				// NO BEACONS, SEARCH ONLY GPS
				console.log("NO BEACONS SEARCH ONLY GPS")
				this.initLocationSearchValidation(readerId)
			}
		},
		// STEP 04 Start search, Show location search overlay, Handle validateUserLocationForDoorOpen response
		async initLocationSearchValidation(readerId, beacons = false) {
			try {
				// SHOW OVERLAY WHEN SEARCH START
				let overlaySerachText = `${this.$t("wiseGymcard.doorOpen.searchingLocationAndBeacon")}... <br> ${this.$t("wiseGymcard.doorOpen.timeOut")} (${this.settings.locationSearchTimeout / 1000}) ${this.$t("wiseGymcard.doorOpen.seconds")}.`
				// Search GPS Only
				if(beacons === false) {
					overlaySerachText = `${this.$t("wiseGymcard.doorOpen.searchingLocation")}... <br> ${this.$t("wiseGymcard.doorOpen.timeOut")} (${this.settings.locationSearchTimeout / 1000}) ${this.$t("wiseGymcard.doorOpen.seconds")}.`
				}

				// Update serach overlay timeout seconds
				let seacrhTimeoutSeoncds = this.settings.locationSearchTimeout / 1000 - 1
				this.seacrhTimeoutSeoncdsInterval = setInterval(() => {
					if(seacrhTimeoutSeoncds < 2) {
						clearInterval(this.seacrhTimeoutSeoncdsInterval);
					}

					let overlaySerachTextUpdated = `${this.$t("wiseGymcard.doorOpen.searchingLocationAndBeacon")}... <br> ${this.$t("wiseGymcard.doorOpen.timeOut")} (${seacrhTimeoutSeoncds}) ${this.$t("wiseGymcard.doorOpen.seconds")}.`
					if(beacons === false) {
						overlaySerachTextUpdated = `${this.$t("wiseGymcard.doorOpen.searchingLocation")}... <br> ${this.$t("wiseGymcard.doorOpen.timeOut")} (${seacrhTimeoutSeoncds}) ${this.$t("wiseGymcard.doorOpen.seconds")}.`
					}
					this.openDoorOverlay.message = overlaySerachTextUpdated;
					seacrhTimeoutSeoncds--;
				}, 1000);
				this.openDoorLoadingOverlay(overlaySerachText)

				// CALL VALIDATE FUNCTION
				const locatioValidationResults = await this.validateUserLocationForDoorOpen(readerId, beacons)
				// Added 500ms timeout, because if location is found imediately, showing and hiding openDoorLoadingOverlay not working properly
				setTimeout(() => {
					// SUCCESSFUL LOCATION VALIDATION
					clearInterval(this.seacrhTimeoutSeoncdsInterval);
					this.openDoorOverlay.dismiss();
					console.log("SUCCCESS validateUserLocationForDoorOpen:", JSON.stringify(locatioValidationResults))

					if(locatioValidationResults?.isInsideDevArea === true) {
						// VALIDATED INSIDE DEV LOCATION
						console.warn("Location validated inside devLocation")
						this.saveDoorOpenAccessLog("Location validated inside devLocation")
					} else {
						// VALIDATED INSIDE GYM AREA
					}

					if(locatioValidationResults?.gpsSuccess === true || locatioValidationResults.beaconSuccess === true) {
						// OPEN DOOR
						this.openDoor(readerId)
					}
				}, 500);
			} catch (error) {
				// NOT VALID LOCATION FOUND
				console.error("validateUserLocationForDoorOpen() TimeoutError", JSON.stringify(error))
				this.openDoorOverlay.dismiss();

				// Check if error is empty object
				if(JSON.stringify(error) === JSON.stringify({})) {
					this.showAlert(this.$t("error"), this.$t("wiseGymcard.doorOpen.error.unknown"))
					this.saveDoorOpenAccessLog("Unknown error, on verifying location.")
					return
				}

				// SAVE FAILURE DOOR OPEN TO VUEX
				// Verify locationServices / Bluetooth are available and enabled
				if(this.deviceBluetoothAvailability.isBluetoothAvailable === true && this.deviceLocationAvailability.isLocationAvailable === true) {
					// Verify GPS not found
					if(error.gpsLocationFound === null) {
						// Nothing found save failure
						this.mutateDoorOpenFailures(this.doorDataSelectedLocationId)
					}
				}

				// Searched only GPS
				if(beacons === false) {
					if(error.gpsLocationFound === null) {
						this.showAlert(this.$t("error"), this.$t("wiseGymcard.doorOpen.error.gpsNotFound"))
						this.saveDoorOpenAccessLog("Only searched GPS and GPS location not found.")
					}
					if(error.gpsLocationFound !== null) {
						this.showAlert(this.$t("error"), this.$t("wiseGymcard.doorOpen.error.gpsOutsideArea"))
						this.saveDoorOpenAccessLog("Only searched GPS and GPS coordinates are outside the approved area.")
					}
				}

				// Searched beacons and GPS
				else {
					console.warn("lastRangedBeaconData", this.lastRangedBeaconData)
					if(error.gpsLocationFound === null) {
						// GPS location not found
						if(this.lastRangedBeaconData?.acceptedBeaconDistance === false) {
							// Beacon too far away
							this.showAlert(this.$t("error"), this.$t("wiseGymcard.doorOpen.error.gpsNotFoundBeaconsTooFar"))
							this.saveDoorOpenAccessLog("GPS location not found and too far from beacon.")
						} else {
							this.showAlert(this.$t("error"), this.$t("wiseGymcard.doorOpen.error.gpsNotFoundBeaconsNotFound"))
							this.saveDoorOpenAccessLog("GPS location not found and beacons not found.")
						}
					}
					if(error.gpsLocationFound !== null) {
						// GPS location outside area
						if(this.lastRangedBeaconData?.acceptedBeaconDistance === false) {
							// Beacon too far away
							this.showAlert(this.$t("error"), this.$t("wiseGymcard.doorOpen.error.gpsOutsideAreaBeaconsTooFar"))
							this.saveDoorOpenAccessLog("GPS coordinates are outside the approved area and too far from beacon.")
						} else {
							this.showAlert(this.$t("error"), this.$t("wiseGymcard.doorOpen.error.gpsOutsideAreaBeaconsNotFound"))
							this.saveDoorOpenAccessLog("GPS coordinates are outside the approved area and beacon not found.")
						}
					}
				}
			}
		},
		// STEP 05 Start searching valid location and beacons if not found timeout (PROMISE)
		validateUserLocationForDoorOpen(readerId, beacons = false) {
			return new Promise((resolve, reject) => {

				console.log("validateUserLocationForDoorOpen(): RUN readerId:", readerId, "beacons:", beacons)

				// Reset when location validation start
				this.debugFoundPositionsArray = []
				this.debugInsideAreaArray = []
				this.locationValidationSuccess = false
				this.mutateUserbeaconsInRanging(null)
				this.mutateDoorToOpenRangedBeacons(null)
				this.lastRangedBeaconData = null

				// Get location doorData
				const doorToOpenDataLocationData =  this.doorAndLocationData.readerLocationData.find(doorToOpen => doorToOpen.readerId === readerId)
				console.log("doorToOpenData:", doorToOpenDataLocationData)

				// CHECK IF ALREADY HAVE CORIDNATES FROM BACKGROUND GPS
				if(this.userCurrentCordinates !== null) {
					console.log("WE ALREADY HAVE USER CORDINATES")
					// Check if position is inside location area
					const containsLocationResults =  this.containsLocationCheck(this.userCurrentCordinates, doorToOpenDataLocationData.locationSettings.coordinates)
					console.log("containsLocationResults:", containsLocationResults)
					this.debugInsideAreaArray.push(containsLocationResults.isInsideArea)

					// Check if we are in insideLocation area / Or in devLocation and allowed to validate from devLocation
					if(containsLocationResults.isInsideArea === true || (containsLocationResults.isInsideDevArea === true && this.settings.validateLocationInsideDevLocation === true)) {
						// Successful location validation with cordinates from background gps
						this.locationValidationSuccess = true
						clearTimeout(this.locationValidationSearchTimeout)
						// Save confirmed location timestamp, user cordinates, beacon and locationId to vuex (state.successfulLocationValidation)
						this.mutateSuccessfulLocationValidation({ beacon: null, locationId: this.doorDataSelectedLocationId })
						resolve({ gpsSuccess: true, isInsideDevArea: containsLocationResults.isInsideDevArea })
						return
					}
				}

				// IF WE ARE HERE NO BACKGROUND GPS CORDINATES FOUND

				// START WATCHING USER POSITION
				console.log("START WATCHING POSITION")
				Geolocation.watchPosition({ enableHighAccuracy:true }, (position, err) => {
					// Verify we still have not confirmed location
					if(this.locationValidationSuccess === false) {

						console.log("watchPosition:", JSON.stringify(position))

						// Make sure we have position data
						if(typeof(position?.coords) === "object") {
							this.debugFoundPositionsArray.push(position)
							this.gpsLocationFound = position.coords
							this.mutateUserCurrentCordinates(position)

							const containsLocationResults =  this.containsLocationCheck(position, doorToOpenDataLocationData.locationSettings.coordinates)
							this.debugInsideAreaArray.push(containsLocationResults.isInsideArea)

							// Check if we are in insideLocation area / Or in devLocation and allowed to validate from devLocation
							if(containsLocationResults.isInsideArea === true || (containsLocationResults.isInsideDevArea === true && this.settings.validateLocationInsideDevLocation === true)) {
								// Location validated succesfully with GPS
								this.locationValidationSuccess = true
								this.stopPositionWatch()
								this.stopBeaconRanging()
								clearTimeout(this.locationValidationSearchTimeout)
								// Save confirmed location timestamp, user cordinates, beacon and locationId to vuex (state.successfulLocationValidation)
								this.mutateSuccessfulLocationValidation({ beacon: null, locationId: this.doorDataSelectedLocationId })
								resolve({ gpsSuccess: true, isInsideDevArea: containsLocationResults.isInsideArea ? false : true })
							}
						}
						if(typeof(err) !== "undefined") {
							console.error("watchPosition:", err)
						}
					}
				}).then( watchId => {
					this.cordinatesPositionWatchId = watchId;
				});

				// If we have beacons
				if(beacons !== false) {
					// Verify we still have not confirmed location
					if(this.locationValidationSuccess === false) {
						// START RANGING BEACONS
						this.startRangeReaderBeacons(beacons)
						// No resolve() here, because
						// Beacons ranging success is handled in --> mounted(): this.iBeaconDelegate.didRangeBeaconsInRegion().subscribe()
					}
				}

				// TIMEOUT LOCATION VALIDATION IN (settings.locationSearchTimeout)
				this.locationValidationSearchTimeout = setTimeout(() => {
					// NO VALID GPS OR BEACON
					this.stopPositionWatch()
					this.stopBeaconRanging()
					reject({ gpsLocationFound: this.gpsLocationFound, beaconFound: false })
				}, this.settings.locationSearchTimeout);
			})
		},

		async startRangeReaderBeacons(beacons) {
			try {
				console.log("startRangeReaderBeacons(): RUN")
				this.mutateUserbeaconsInRanging(beacons)
				// LOOP ALL BEACONS FROM READER
				beacons.forEach((beacon) => {
					// console.log("beacon", JSON.stringify(beacon));
					const beaconRegion = IBeacon.BeaconRegion(beacon.beaconIdentifier, beacon.beaconUUID, beacon.beaconMajor, beacon.beaconMinor)
					IBeacon.startRangingBeaconsInRegion(beaconRegion).then(() => {
						// console.log('START RANGING BEACONS IN REGION:', JSON.stringify(beaconRegion))
					}).catch((error) => {
						console.error(error);
					});
				})
			} catch (error) {
				console.error(error)
			}
		},

		checkRangedBeacons(beaconRange) {
			// Verify we dont have validated location yet
			if(this.locationValidationSuccess === false) {
				// Check if we have beacon(s) in range
				if(beaconRange?.beacons.length > 0) {
					// Loop through found beacons
					for (const foundBeacon of beaconRange.beacons) {
						// Check if beacon uuid match
						const confirmedBeacon = this.beaconsInRanging.find((beacon) => beacon.beaconUUID.toUpperCase() === foundBeacon.uuid.toUpperCase())
						// If we have beacon match with uuid, check distance
						if(confirmedBeacon) {
							if(confirmedBeacon?.acceptedBeaconDistance >= foundBeacon.accuracy && foundBeacon.accuracy > 0) {
								// SUCCESS Beacon is inside acceptedBeaconDistance
								return {beaconFound: true, acceptedBeaconDistance: true, beacon: confirmedBeacon}
							} else {
								// FAILURE Beacon too far away
								return {beaconFound: true, acceptedBeaconDistance: false, beacon: confirmedBeacon}
							}
						} else {
							return {beaconFound: false, acceptedBeaconDistance: false}
						}
					}
				}
			}
		},

		stopBeaconRanging() {
			console.log("stopBeaconRanging(): RUN")
			// If we have beacons ranging stop all
			if(this.beaconsInRanging !== null) {
				this.beaconsInRanging.forEach((beacon) => {
				const beaconRegion = IBeacon.BeaconRegion(beacon.beaconIdentifier, beacon.beaconUUID, beacon.beaconMajor, beacon.beaconMinor)
				IBeacon.stopRangingBeaconsInRegion(beaconRegion).then(() => {
					// console.log("STOP RANGING BEACON REGION:", JSON.stringify(beaconRegion))
				}).catch((error) => {
					console.error(error);
				});
			})
			}
		},

		async adminDoorOpen(readerId) {
			console.log("Open door for admin readerId:", readerId, "locationId:", this.doorDataSelectedLocationId)
			await this.openDoorLoadingOverlay();
			const deviceInfo = await Device.getInfo();
			const doorData = {
				appId: this.settings.appId,
				appVersion: this.settings.appVersion,
				personId: this.user.personId,
				locationId: this.doorDataSelectedLocationId,
				readerId: readerId,
				currentCoords: this.userCurrentCordinates ? this.userCurrentCordinates : false,
				// lastRangedBeacons: BeaconService.lastRangingBeacons.beacons,
				doOpenDoor: this.user.isAdmin.doorAccess, // Is the door really opening? This is used in development and testing.
				isDebug: this.user.isAdmin.doorAccess, // Is this an admin opening? Checking the admin information on the server also.
				//isFailureOpen: isFailureOpen, // Hoax open
				//isCheckinOpen: isCheckinOpen, // Only check-in, door will not be opened
				fullDeviceData: deviceInfo,
				autoConfirmOnEntryReaderId: false // Event confirmation
			};

			// Get door name
			const doorName = this.getDoorNameByReaderId(readerId)

			try {
				// Send open door POST to server
				const { data } = await axios.post(`${this.activeHost.ajaxUrl}?controller=ajax&openaccessdoor=1&lang=${this.userLocale}&appauth=${this.activeHost.appauth}`, doorData)
				console.log(data)
				this.openDoorOverlay.dismiss();
				this.showAlert(data.openTitle,`${doorName}<br><br> ${this.$t("wiseGymcard.doorOpen.doorOpened")}`)
			} catch (error) {
				this.openDoorOverlay.dismiss();
				if(error.response){
          console.error(error.response.data);
					this.showAlert(error.response.data.openTitle, `${doorName}<br><br> ${error.response.data.openResult}`)
        } else {
          console.error(error)
					this.showAlert(this.$t("error"),error)
        }
			}
		},
		async openDoor(readerId, hoaxOpen = false) {
			console.log("Open door for user readerId:", readerId, "locationId:", this.doorDataSelectedLocationId)
			const overlayText = this.isCheckinOpen === true ? "Check-in..." : this.$t("wiseGymcard.doorOpen.openingDoor")
			await this.openDoorLoadingOverlay(overlayText);
			const deviceInfo = await Device.getInfo();
			const doorData = {
				appId: this.settings.appId,
				appVersion: this.settings.appVersion,
				personId: this.user.personId,
				locationId: this.doorDataSelectedLocationId,
				readerId: readerId,
				currentCoords: this.userCurrentCordinates ? this.userCurrentCordinates.coords : false,
				lastRangedBeacons: [this.successfulLocationValidation.beacon],
				doOpenDoor: true, // Is the door really opening? This is used in development and testing.
				isDebug: false, // Is this an admin opening? Checking the admin information on the server also.
				isFailureOpen: hoaxOpen, // Hoax open
				isCheckinOpen: this.isCheckinOpen, // Only check-in, door will not be opened
				fullDeviceData: deviceInfo,
				autoConfirmOnEntryReaderId: this.settings.eventConfirm?.autoConfirmOnEntryReaderId ? this.settings.eventConfirm.autoConfirmOnEntryReaderId : false // Event confirmation
			};

			// Get door name
			const doorName = this.getDoorNameByReaderId(readerId)

			try {
				// Send open door POST to server
				const { data } = await axios.post(`${this.activeHost.ajaxUrl}?controller=ajax&openaccessdoor=1&lang=${this.userLocale}&appauth=${this.activeHost.appauth}`, doorData)
				console.log(data)
				this.openDoorOverlay.dismiss();
				this.showAlert(data.openTitle,`${doorName}<br><br>${data.openResult}`)
			} catch (error) {
				this.openDoorOverlay.dismiss();
				if(error.response){
          console.error(error.response.data);
					this.showAlert(error.response.data.openTitle, `${doorName}<br><br> ${error.response.data.openResult}`)
        } else {
          console.error(error)
					this.showAlert(this.$t("error"),error)
        }
			}
		},
		//
		// SIMPLE HELPER FUNCTIONS FOR DOOR OPEN
		//
		async stopPositionWatch() {
			console.log("stopPositionWatch(): RUN")
			clearInterval(this.seacrhTimeoutSeoncdsInterval);
			await Geolocation.clearWatch({id: this.cordinatesPositionWatchId})
		},
		async openDoorLoadingOverlay(text) {
			this.openDoorOverlay = await loadingController.create({
				duration: this.settings.locationSearchTimeout,
				cssClass: "wise-door-overlay",
				//spinner: "lines-small",
				message: text ? text : this.$t("wiseGymcard.doorOpen.openingDoor"),
			});
			await this.openDoorOverlay.present();
		},
	},
	components: { IonRefresher, IonRefresherContent },
};
</script>
<style>
	.wise-picker-modal::part(content) {
		background: transparent;
		position: absolute;
		top: 10%;
		left: 10%;
	}
	.wise-door-overlay .loading-wrapper {
		display: block;
		padding: 20px;
	}
	.wise-door-overlay .loading-spinner {
		text-align: center;
		margin-bottom: 10px;
	}
	.wise-door-overlay .loading-content {
		margin: 0 !important;
		text-align: center;
	}
</style>