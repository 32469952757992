import { Preferences } from '@capacitor/preferences';
import WiseSettings from '@/WiseSettings.js';
import EcomService from './EcomService.js';

async function getAdminModeMemory() {
    const { value } = await Preferences.get({ key: `reservationAdminMode-${ WiseSettings.appId }` });

    try {
        return JSON.parse(value);
    } catch(e) {
        await Preferences.remove({ key: `reservationAdminMode-${ WiseSettings.appId }` })
    }
    
    return null;
}
/**
 * 
 * @param {Boolean} value 
 */
function setAdminModeMemory(value) {
    Preferences.set({ key: `reservationAdminMode-${ WiseSettings.appId }`, value: JSON.stringify(value) })
}

export default {
    namespaced: true,
    state: {
        requireLoggedIn: false,
        admin: false,
        adminMode: false,
        hasPrivileges: false,
        mobile: window.innerWidth <= 768,
        isEmbed: false,
    },
    getters: {
        loggedIn: (state) => state.loggedIn,
        requireLoggedIn: (state) => state.requireLoggedIn,
        mobile: (state) => state.mobile,
        admin: (state) => state.admin,
        adminMode: (state) => state.adminMode,
        isEmbed: (state) => state.isEmbed, 
        modalIsOpen: (state) => state.modal !== null,
        modalName: (state) => state.modal || '',
        modalClass: (state) => state.modalClass,
    },
    mutations: {
        setMobile(state, value) {
            state.mobile = value;
        },
        setModal(state, name) {
            state.modal = name;

            if (name === null) {
                state.modalClass = '';
            }
        },
        setModalClass(state, modalClass) {
            state.modalClass = modalClass;
        },
        toggleAdminMode(state, value) {
            if (typeof value !== 'undefined') {
                state.adminMode = value;
            } else {
                state.adminMode = !state.adminMode;
            }
            
            setAdminModeMemory(state.adminMode);
        },
        setIsEmbed(state, value) {
            state.isEmbed = value;
        },
    },
    actions: {
        reset({ /*state,*/ commit }) {
            //state.admin = false;
            //state.adminMode = false;

            commit('res_calendar/reset', null, { root: true });
            commit('res_golf/reset', null, { root: true });
            commit('res_ecom/reset', null, { root: true });
            commit('res_reservations/reset', null, { root: true });
        },
        async init({ state }, productId) {
            const { data: productSearch } = await EcomService.getProductInfo(productId)

            if (!Array.isArray(productSearch.rows) || productSearch.rows.length === 0) {
                return Promise.reject('Product not available')
            }

            const response = await EcomService.getProductUserInfo(productId)
            const { data } = response;

            if (!data.success) {
                return Promise.reject(data.error);
            }
            // user can be an owner of the product but otherwise 
            // is not a admin or root user in the system
            state.hasPrivileges = data.hasPrivileges;
            state.loggedIn = data.loggedIn;

            let memoryAdminMode = await getAdminModeMemory();
            
            if (state.hasPrivileges) {
                state.admin = true;
                state.adminMode = memoryAdminMode !== null ? memoryAdminMode : true;
            } else {
                state.admin = false;
                state.adminMode = false;
            }

            return response;
        }
    }
}