import { toastController } from "@ionic/vue";
import { mapState, mapGetters } from "vuex";
import { Capacitor } from '@capacitor/core';
import { Preferences } from '@capacitor/preferences';
import { FCM } from "@capacitor-community/fcm";
import { PushNotifications } from "@capacitor/push-notifications";
import axios from 'axios';
import { param } from '../WiseEcom/EcomReservation/store/helpers.js'

export default {
	data: () => ({
		device: Capacitor.getPlatform(), // Get device platform to check is app running on Android, iOS
		notificationListenersRegistered: false,
	}),
	computed: {
		...mapState({
			loggedIn: state => state.user.loggedIn,
			settings: state => state.common.settings,
			userLocale: state => state.common.userLocale,
			notificationsSettings: state => state.common.notificationsSettings,
			notificationsEnabled: state => state.common.notificationsSettings.enabled,
			notificationsTopics: state => state.common.notificationsSettings.topics,
		}),
		...mapGetters({
			allHosts: 'common/allHosts',
		}),
	},	
	watch: {
		async loggedIn(val) {
			if(val === true) {
				// When user logs in
				this.registerPush()
			}
		},

		// When user changes language, set default push topics for selected language
		async userLocale() {
			if (!this.userLocale) return;
			// console.log(`RESET PUSH TOPICS FOR ${this.userLocale}`);
			// Get only topics for selected locale
			const defaultTopicsForUserChoosedLanguage = Object.fromEntries(Object.entries(this.settings.topicDefaults).filter(([key]) => key.includes('-'+this.userLocale.split("-")[0])))
			// Check we really have topics (When user logs out [this.userLocale] is empty for a moment, and results no topics, skip this)
			if (Object.keys(defaultTopicsForUserChoosedLanguage).length > 0) {
				await this.$store.commit('common/mutateDefaultTopics', defaultTopicsForUserChoosedLanguage)
			}
		},
		
		// When user toggles push notifications on/off completely
		notificationsEnabled(val) {
			console.log('watcher notificationsEnabled', Date.now(), val)
			this.onNotificationTopicChange(val)
		},
		
		// If user toggles push notification TOPICS on/off
		notificationsTopics: {
			async handler() {
				if(this.loggedIn === true) {
					await this.handleTopics()
					await this.$store.dispatch("user/updateUserSettings", this.notificationsSettings)
				}
			},
			deep: true
		}
	},
	methods: {
		onNotificationTopicChange(val) {
			console.log("onNotificationTopicChange:", val)
			if(val === true) {
				this.registerPush()
			} else {
				this.unregisterPush()
			}
		},
		async subscribeTopic(topic) {
			try {
				console.log("FCM subscribe to topic:", topic)
				await FCM.subscribeTo({ topic })
			} catch (error) {
				console.error("Error subscriping to FCM topic:", JSON.stringify(error))
			}
		},

		async unsubscribeTopic(topic) {
			try {
				console.log("FCM unsubscribe topic:", topic)
				await FCM.unsubscribeFrom({ topic })
			} catch (error) {
				console.error("Error unsubscriping FCM topic:", JSON.stringify(error))
			}
		},

		async getSubscribedTopics() {
			try {
				const { value } = await Preferences.get({ key: `wise-subscribed-topics-${this.settings.appId}`});
				if(value !== null) {
					return JSON.parse(value)
				}
				return []
			} catch (error) {
				console.error("Error while fetching/parsing wise-subscribed-topics:", error)
				return []
			}
		},

		async unsubscribeAllTopics() {
			// Get currently subscribed topics
			let wiseSubscribedTopics = await this.getSubscribedTopics();

			// Unsubscribe from all current topics.
			for (const topic of wiseSubscribedTopics) {
				this.unsubscribeTopic(topic);
			}

			// Save (un)subscribed topics
			await Preferences.set({ key: `wise-subscribed-topics-${this.settings.appId}`, value: JSON.stringify([]) });
		},

		// Unregister push notification, disable all listeners, remove fcm token, unsubscribe from all topics, update state to db
		async unregisterPush() {
			console.log("Unregister: PUSH NOTIFICATIONS")
			const isMobile = this.device === "android" || this.device === "ios";
			// Push Notifications only work on device (no browser implementation)
			if (isMobile === false) {
				return;
			}

			try {
				// Remove FCM instance
				await FCM.deleteInstance()
				PushNotifications.removeAllListeners()
				// Clear FCM token from vuex
				await this.$store.commit('common/mutateFirebaseToken', "")
				await this.unsubscribeAllTopics()
				// Update notification settings to DB
				await this.$store.dispatch("user/updateUserSettings", this.notificationsSettings)

				this.notificationListenersRegistered = false;
			} catch (error) {
				console.error("Error on unregisterPush(),", error)
			}
		},

		// Register push notifications, add listeners, get fcm token, subscribe to topics, update state to db
		async registerPush() {
			const isMobile = this.device === "android" || this.device === "ios";
			
			// Check are notifications enabled?
			if(this.notificationsEnabled === false) {
				console.log("Notifications are not enabled, abort registerPush()")
				return
			}

			// console.log("Register: PUSH NOTIFICATIONS")

			// Push Notifications only work on device (no browser implementation)
			if (isMobile === false) {
				return;
			}

			try {

				try {
					// https://stackoverflow.com/a/70575407
					await PushNotifications.requestPermissions().then(async (permission) => {
						// is there other cases? granted was the only one handled
						const permissionGranted = permission.receive == "granted";
						if (permissionGranted === false) {
							// No permission for push granted
							console.error("No Permission for Notifications!")
							// alert('No Permission for Notifications!')
							return;
						}

						// Get push token for iOS
						if (Capacitor.getPlatform() == 'ios') {
							try {
								const { token } = await FCM.getToken();
								const iosToken = token;
								console.log(`iOS push token: ${iosToken}`)
								await this.$store.commit('common/mutateFirebaseToken', iosToken)
							} catch (error) {
								console.error("Error getting ios push token", error)
							}
							console.log("REGISTER IOS PUSH")
							await PushNotifications.register();
							console.log("IOS PUSH REGISTERED")
						} else if (Capacitor.getPlatform() == 'android') { // Get push token for android
							PushNotifications.addListener('registration', async ({ value }) => {
								const androidToken = value; // this is token for Android use this token
								console.log(`Android push token: ${androidToken}`)
								await this.$store.commit('common/mutateFirebaseToken', androidToken)
							});
							console.log("REGISTER ANDROID PUSH")
							await PushNotifications.register()
							console.log("ANDROID PUSH REGISTERED")
						}

						// Add registration error if there are.
						await PushNotifications.addListener("registrationError", (error) => {
							console.error(`Error registering push notification ${JSON.stringify(error)}`);
						})
					});
					
					await this.registerNotificationListeners();
				} catch (error) {
					console.error("Error registering PUSH NOTIFICATIONS:", error)
				}

				await this.handleTopics();
				await this.$store.dispatch("user/updateUserSettings", this.notificationsSettings)
				// @capacitor-community/fcm Token refresh not implemented https://github.com/capacitor-community/fcm/issues/42
				// QUESTION: Is token refresh really needed? (To get new token fcm.deleteInstance() and fcm.getToken())
			} catch (error) {
				console.error("Error on registerPush():", error)
			}
		},

		async handleTopics() {
			// Check if we are subscribed to any other topics. (Get subscribed topics from localStorage)
			let wiseSubscribedTopics = await this.getSubscribedTopics()

			// Unsubscribe from any topic not in settings. This is because of multiple locations potentially causing problems with "ghost" topics.
			// TODO: WiseGolf push, voiko käyttäjä seurata useamman seuran notifikaatioita?
			if (wiseSubscribedTopics && wiseSubscribedTopics.length) {
				for (let i=0;i < wiseSubscribedTopics.length;i++) {
					const subbedTopic = wiseSubscribedTopics[i]

					if (!(subbedTopic in this.notificationsTopics)) {
						// Found foreign topic
						console.log("Found foreign topic '" + subbedTopic + "'. Unsubscribing");

						// Unsubscribe from foreign topic
						this.unsubscribeTopic(subbedTopic);

						// Remove foreign topic from wiseSubscribedTopics
						wiseSubscribedTopics.splice(i, 1);
					}
				}
			}

			// Subscribe to topics defined in settings.
			for (const [topic, enabled] of Object.entries(this.notificationsTopics)) {
				if (['enabled','token'].includes(topic)) {
					continue;
				}
				// Subscribe only if topic in notifications settings is actually set in current app topics settings. Required for useMultipleLocations
				if (enabled === true && Object.keys(this.settings.topics).includes(topic)) {
					this.subscribeTopic(topic)
					// Add topic we just subscribed to into subscribed topics in localStorage (if not yet there)
					if (!wiseSubscribedTopics.includes(topic)) {
						wiseSubscribedTopics.push(topic)
					}
				} else {
					// Remove from wiseSubscribedTopics
					const index = wiseSubscribedTopics.findIndex((elem) => elem === topic);

					if (index !== -1) {
						this.unsubscribeTopic(topic)
						wiseSubscribedTopics.splice(index, 1);
					}
				}
			}

			// Save subscribed topics to localStorage
			await Preferences.set({ key: `wise-subscribed-topics-${this.settings.appId}`, value: JSON.stringify(wiseSubscribedTopics) });
		},

		async registerNotificationListeners() {
			if (this.notificationListenersRegistered) {
				console.log('registerNotificationListeners: listeners already registered')
				return;
			}
			console.log('registerNotificationListeners: register listeners')
			const transformPayload = (str) => {
				let payload;
				try {
					payload = JSON.parse(str);

					if (payload === null) return payload;
				} catch(error) {
					console.log('transformPayload', error)
					return null;
				}
				const { 
					notification, 
					messageType, 
					articleId, 
					productId,
					ownerName,
					dateTimeStart,
					reservationTimeId,
					reservation,
				} = payload;
				const { title, body } = notification;

				return {
					title, 
					body,
					messageType,
					articleId,
					productId,
					// spesific for reservation reminder
					ownerName,
					dateTimeStart,
					reservationTimeId,
					reservation,
				}
			}
			// Add Notification received (called when app is foreground)
			await PushNotifications.addListener("pushNotificationReceived", (notification) => {
				const payload = transformPayload(notification?.data?.payload || 'null')
				
				console.log(`pushNotificationReceived`, notification);
				if (isFinite(payload.reservationTimeId)) {
					this.handleReservationReminder(payload)
				} else {
					this.handlePushnotification(payload, true)
				}
			})
			// Add Action performed (called when user tabs notification on device menu)
			await PushNotifications.addListener("pushNotificationActionPerformed", (notificationTab) => {
				const payload = transformPayload(notificationTab?.notification?.data?.payload || 'null')

				console.log(`pushNotificationActionPerformed`, notificationTab);
				if (isFinite(payload.reservationTimeId)) {
					this.handleReservationReminder(payload)
				} else {
					this.handlePushnotification(payload)
				}
			})

			this.notificationListenersRegistered = true;
		},

		async handleReservationReminder(payload) {
			const { 
				title, 
				body,
				ownerName,
				//dateTimeStart,
				reservationTimeId,
				//reservation,
			} = payload
			const [ hostSettings ] = this.allHosts.filter(row => row.name === ownerName)
			const buttons = [
				{
					side: 'end',
					text: this.$t('confirm tee time'),
					handler: () => {
						this.confirmTeeTime(reservationTimeId, hostSettings)
						.then(() => {
							this.$store.commit(
								'res_golf/setReservationsUpdate', 
								Date.now()
							)
						})
					}
				},
				{
					side: 'end',
					text: this.$t('close'),
					role: 'cancel',
				}
			]

			const toast = await toastController.create({
				header: title,
				message: body,
				position: 'top',
				color: 'primary',
				buttons,
			})
			await toast.present();
		},

		confirmTeeTime(reservationTimeId, settings) {
			const params = {};
			let queryString = '';
			
			params.appauth = settings.appauth;
	
			if (Object.keys(params).length > 0) {
				queryString = '&'+param(params);
			}
	
			return axios({
				method: 'GET',
				url: settings.ajaxUrl + '?reservations=confirmgolfteetime&reservationtimeid='+reservationTimeId + queryString
			})
		},

		handlePushnotification(pushData, appForeground = false) {
			const { notificationRedirects } = this.settings;
			const newsRedirect = notificationRedirects?.news || '/today/article/';
			console.log("handlePushnotification(): RUN", pushData, appForeground)
			
			if (pushData.articleId) {
				// HANDLE SITUATION WHEN PUSH CONTAINES KEY -> articleId
				this.pushNotificationToast(
					pushData.title, 
					pushData.body, 
					`${ newsRedirect }${ pushData.articleId }`
				)
				return;
			}

			if (pushData.productId) {
				console.log("WE HAVE: productId")
				// TODO Handle productId key from push message
				return;
			}

			this.pushNotificationToast(
				pushData.title, 
				pushData.body
			)
		},

		async pushNotificationToast(title = false, body, target = null, color = "primary") {
			const buttons = target !== null ? 
			[
				{
					side: 'end',
					text: "Näytä",
					handler: () => {
						console.log('redirect to', target)
						this.$router.push(target)
					}
				},
				{
					side: 'end',
					text: this.$t("close"),
					role: "cancel",
				}
			] : 
			[
				{
					side: 'end',
					text: 'OK',
					role: "cancel",
				}
			];

			const toast = await toastController.create({
				header: title ? title : false,
				message: body,
				// duration: 30000,
				position: "top",
				color,
				buttons,
			})
			await toast.present();
		}
	},
	async mounted() {
		if(this.loggedIn === true) {
			// When user opens app and is already logged in
			this.registerPush()
		}
	},
}