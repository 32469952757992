// install global components from this folder
// not all components need to be installed this way!!
import WnPages from './WnPages.vue'
import WnTablePages from './WnTablePages.vue'
import WnDropDown from './WnDropDown.vue'

const components = {
	WnPages,
    WnTablePages,
    WnDropDown,
}

export default {
	install(app) {
		Object.keys(components).forEach((name) => {
			const component = components[name];
			app.component(name, component);
		})
	}
}