import { _locale } from '../WiseEcom/services/WiseLocale';
import { wiseAlert } from '../WiseEcom/services/WiseAlert';
import store from '../store';
import router from './index.js';

export async function defaultGuard(to, from, next) {
    // User is logged in, validate accessToken
    store.dispatch('user/validateAccessTokenFromStorage')
    .then(async (accessTokenValid) => {
        if (accessTokenValid === false) {
            console.log(`%cAccess token unvalid, user need to re-login`, 'color: yellow; padding: 5px 0')
            wiseAlert({
                mode: 'ios',
                backdropDismiss: false,
                message: _locale('login.sessionExpired'),
                buttons: [
                    {
                        text: 'OK',
                        handler: () => {
                            // reset all states before trying to log back in
                            store.dispatch('user/logOut')
                            router.replace('/');
                        },
                    },
                ]
            })
            return
        }
    })
    // All good, user can access route
    next()
}