// notifications to app front page from the developer to the user
// these are visible until the user confirms/uses action 
export default {
    '/golf/front': [
        {
            id: 'wisegolf-1693974481831',
            color: 'primary',
            title: 'Omat tiedot',
            content: 'Voit nyt valita haluatko varausvahvistuksen sähköpostiisi. Sähköposti otsikolla "Varausvahvistus" lähetetään, kun varaat itse tai sinut on liitetty lähtöön toisen toimesta.',
            path: '/golf/account',
            expired: new Date('2023-11-01 00:00:00') < new Date()
        },
        {
            id: 'wisegolf-1676534063358',
            color: 'primary',
            title: 'Uutta tässä versiossa',
            content: 'Käy tarkistamassa uudet push-ilmoitusasetukset Omat tiedot-sivulla',
            path: '/golf/account',
            expired: new Date('2023-07-01 00:00:00') < new Date()
        },
    ],
}